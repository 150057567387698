import { css } from '@emotion/react'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type LoadingProps = {
  fullScreen?: boolean
}
export const Loading = ({ fullScreen = false }: LoadingProps) => {
  return (
    <div
      css={css`
        ${fullScreen
          ? css`
              width: 100vw;
              height: 100vh;
            `
          : css`
              width: 100%;
              height: 100%;
            `}

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
      `}
    >
      <FontAwesomeIcon icon={faSpinnerThird} spin />
    </div>
  )
}
