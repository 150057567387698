import styled from '@emotion/styled'
import capsize from 'capsize'

const fontMetrics = {
  capHeight: 2048,
  ascent: 2728,
  descent: -680,
  lineGap: 0,
  unitsPerEm: 2816,
}

const lineGapMap = {
  14: 20,
  16: 24,
  18: 27,
  22: 33,
  24: 32,
}

type FontSizes = keyof typeof lineGapMap

const styles = (fontSize: FontSizes) =>
  capsize({
    fontMetrics,
    fontSize,
    leading: lineGapMap[fontSize],
  })

export const Subheading = styled.p<{
  fontSize?: FontSizes
}>`
  letter-spacing: 0.02em;

  ${({ fontSize = 16 }) => ({ ...styles(fontSize) })}
  ${({ fontSize = 16 }) => ({
    fontWeight: fontSize !== 22 ? 500 : 600,
  })}
`
