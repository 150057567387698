import { css } from '@emotion/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faBell,
  faBrowser,
  faCar,
  faChartLine,
  faChevronRight,
  faCircleDollar,
  faCompass,
  faFileChartColumn,
  faHome,
  faSearch,
  faTags,
} from '@fortawesome/pro-regular-svg-icons'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  AccordionButton as AccordionButtonReach,
  AccordionItem,
  AccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { Search } from '@/components/features/navigation/Search'
import { SidebarMenu } from '@/components/features/navigation/SidebarMenu'
import { useAuthContext } from '@/components/global/AuthContext'
import { Image } from '@/design-system/Image'
import { Tag } from '@/design-system/Tag'
import { Text } from '@/design-system/Text'

import {
  UserRole,
  useUnreadNotificationsCountQuery,
} from '../../../generated/types-and-hooks'
import { SidebarLink } from './SidebarLink'

type AccordionButtonProps = {
  icon: IconProp
}

const AccordionButton: FC<PropsWithChildren<AccordionButtonProps>> = ({
  icon,
  children,
}) => {
  const { isExpanded } = useAccordionItemContext()

  return (
    <AccordionButtonReach
      css={css`
        width: 100%;
        cursor: pointer;
        border-radius: 3px;
        padding: 8px 12px;
        transition: background-color var(--t-primary);
        border: 1px solid transparent;

        &[data-state='open'] {
          font-weight: 500;
        }

        &:hover {
          background-color: var(--color-gray-100-rgb);
        }
      `}
    >
      <div className="flex items-center">
        <div className="flex flex-1 items-center">
          <div
            css={css`
              margin-right: 20px;
              width: 16px;
              display: flex;
              justify-content: center;
            `}
          >
            <FontAwesomeIcon
              css={css`
                font-size: 16px;
              `}
              icon={icon}
            />
          </div>
          <Text
            css={css`
              user-select: none;
              color: var(--color-blue-950-rgb);
            `}
          >
            {children}
          </Text>
        </div>
        <FontAwesomeIcon
          css={css`
            transition: var(--t-primary);
            color: var(--c-grey-700);
            font-size: 14px;
          `}
          rotation={isExpanded ? 90 : undefined}
          icon={faChevronRight}
        />
      </div>
    </AccordionButtonReach>
  )
}

const NotificationsSidebarLink = ({
  icon,
  href,
  name,
}: {
  name: string
  icon: IconDefinition
  href: string
}) => {
  const { asPath } = useRouter()
  const { dealer } = useAuthContext()
  const { data } = useUnreadNotificationsCountQuery({
    pollInterval: 60_000,
  })
  const thisDealerNotificationCount = data?.unreadNotificationsCount.filter(
    (e) => e.dealershipId === dealer.id,
  )

  return (
    <Link href={href}>
      <div
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          ${icon
            ? css`
                padding: 8px 12px;
              `
            : css`
                padding: 10px 12px;
              `}

          border-radius: 3px;
          transition: var(--t-primary);
          color: var(--color-blue-950-rgb);
          border: 1px solid transparent;

          &:hover {
            background-color: var(--color-gray-100-rgb);
          }

          ${asPath === href &&
          css`
            background-color: var(--color-gray-100-rgb);
            font-weight: 500;
            color: var(--color-blue-700-rgb);
            border-color: var(--color-blue-100-rgb);
          `}
        `}
      >
        <div
          css={css`
            margin-right: 20px;
            width: 16px;
            display: flex;
            justify-content: center;
            position: relative;
          `}
        >
          <FontAwesomeIcon
            css={css`
              font-size: 16px;
            `}
            icon={icon}
          />
          {!!data &&
            !!thisDealerNotificationCount &&
            thisDealerNotificationCount.length > 0 &&
            thisDealerNotificationCount[0].count > 0 && (
              <div
                css={css`
                  height: 5px;
                  width: 5px;
                  position: absolute;
                  border-radius: 9999px;
                  top: -3px;
                  right: -2px;
                  background-color: var(--color-red-500-rgb);
                `}
              />
            )}
        </div>

        <Text
          css={css`
            user-select: none;
            flex: 1;
          `}
        >
          {name}
        </Text>
        {!!data &&
          thisDealerNotificationCount &&
          thisDealerNotificationCount.length > 0 &&
          thisDealerNotificationCount[0].count > 0 && (
            <div className="absolute right-2">
              <Tag>{thisDealerNotificationCount[0].count}</Tag>
            </div>
          )}
      </div>
    </Link>
  )
}

export const SearchSidebarButton = () => {
  useHotkeys('/', () => {
    setTimeout(() => {
      setSearchModal(true)
    }, 10)
  })
  const [searchModal, setSearchModal] = useState(false)

  return (
    <>
      <Search
        open={searchModal}
        onDismiss={() => {
          setSearchModal(false)
        }}
      />
      <button
        onClick={() => {
          setSearchModal(true)
        }}
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 8px 12px;
          border-radius: 3px;
          transition: var(--t-primary);
          border: 1px solid transparent;

          &:hover {
            background-color: var(--color-gray-100-rgb);

            .search-icon {
              opacity: 1;
            }
          }
        `}
      >
        <div
          css={css`
            margin-right: 20px;
            width: 16px;
            display: flex;
            justify-content: center;
          `}
        >
          <FontAwesomeIcon
            css={css`
              font-size: 16px;
            `}
            icon={faSearch}
          />
        </div>

        <Text
          css={css`
            user-select: none;
            color: var(--color-blue-950-rgb);
            flex: 1;
            text-align: left;
          `}
        >
          Search
        </Text>
        <div
          className="search-icon"
          css={css`
            border: 1px solid var(--c-grey-600);
            border-radius: 2px;
            height: 14px;
            width: 14px;
            display: flex;
            justify-content: center;
            padding-top: 1px;
            opacity: 0;
            transition: var(--t-primary);
          `}
        >
          <p
            css={css`
              font-size: 9px;
              line-height: 1;
              color: var(--c-grey-600);
            `}
          >
            /
          </p>
        </div>
      </button>
    </>
  )
}

type Feature = {
  name: string
  icon: IconDefinition
} & (
  | { links: { name: string; href: string }[] }
  | { href: string; disabled?: boolean }
)

type Section = {
  features: Feature[]
}

export const Sidebar: FC = () => {
  const { user, dealer } = useAuthContext()
  const { query, asPath } = useRouter()
  const dealerId = query.dealerId

  const membership = user.dealershipMemberships.find((m) => {
    return m.dealership.id === dealer.id
  })

  if (!membership) {
    throw new Error('Membership not found')
  }

  const sections: Section[] = [
    {
      features: [
        {
          name: 'Home',
          icon: faHome,
          href: `/${dealerId}/home`,
        },
        {
          name: 'Notifications',
          icon: faBell,
          href: `/${dealerId}/notifications`,
        },
      ],
    },
    ...(membership.role === UserRole.Admin
      ? [
          {
            features: [
              {
                name: 'Search',
                icon: faFileChartColumn,
                href: `/${dealerId}/reports`,
              },
              {
                name: 'Reports',
                icon: faChartLine,
                href: `/${dealerId}/reports`,
              },
            ],
          },
        ]
      : []),
    {
      features: [
        {
          name: 'Website',
          icon: faBrowser,
          links: [
            {
              name: 'Pages',
              href: `/${dealerId}/website/pages`,
            },
            {
              name: 'Blog Posts',
              href: `/${dealerId}/website/posts`,
            },
            {
              name: 'Employees',
              href: `/${dealerId}/website/employees`,
            },
            {
              name: 'Forms',
              href: `/${dealerId}/website/forms`,
            },
            {
              name: 'Media',
              href: `/${dealerId}/website/media`,
            },
            {
              name: 'Pre-Order Bank',
              href: `/${dealerId}/website/pre-order-bank`,
            },
          ],
        },
        ...(membership.role === UserRole.Admin
          ? [
              {
                name: 'Leads',
                icon: faCircleDollar,
                links: [
                  {
                    name: 'Leads',
                    href: `/${dealerId}/crm/leads`,
                  },
                  {
                    name: 'Inquiries',
                    href: `/${dealerId}/crm/inquiries`,
                  },
                  {
                    name: 'Accounts',
                    href: `/${dealerId}/crm/accounts`,
                  },
                  // {
                  //   name: 'Price Alerts',
                  //   href: `/${dealerId}/crm/price-alerts`,
                  // },
                ],
              },
              {
                name: 'Inventory',
                icon: faCar,
                links: [
                  {
                    name: 'Vehicles',
                    href: `/${dealerId}/inventory/vehicles`,
                  },
                  {
                    name: 'Sync Activity',
                    href: `/${dealerId}/inventory/sync-activity`,
                  },
                ],
              },
              {
                name: 'Offers',
                icon: faTags,
                href: `/${dealerId}/offers`,
              },
              {
                name: 'VIN Explorer',
                icon: faCompass,
                href: `/${dealerId}/vin-explorer`,
              },
            ]
          : []),
      ],
    },
  ]

  return (
    <nav
      css={css`
        height: 100vh;
        position: sticky;
        width: 100%;
        padding: 28px 16px 16px 16px;
        display: grid;
        gap: 64px;
        border-right: 1px solid var(--c-grey-300);
        overflow-y: scroll;
        background-color: var(--c-grey-50);
        top: 0;
        ${asPath.includes('/website/pages/') &&
        css`
          display: none;
        `}
      `}
    >
      <div>
        <div
          css={css`
            display: grid;
            gap: 32px;
            grid-template-rows: auto;
            align-items: start;
          `}
        >
          <div>
            <Link
              href={`/${dealerId}/home`}
              className="ml-3 inline-block w-9 border border-transparent"
            >
              <Image
                src="driverseat/DS_Squircle_s5le2p"
                width={140}
                height={140}
                alt="Driverseat logo"
              />
            </Link>
          </div>

          {sections.map((section, index) => {
            return (
              <div
                key={index}
                css={css`
                  display: grid;
                  gap: 8px;
                `}
              >
                <Accordion collapsible={true}>
                  <div
                    css={css`
                      display: grid;
                      gap: 4px;
                    `}
                  >
                    {section.features.map((feature) => {
                      if (feature.name === 'Search') {
                        return <SearchSidebarButton key={feature.name} />
                      } else if (
                        feature.name === 'Notifications' &&
                        !('links' in feature)
                      ) {
                        return (
                          <div key={feature.name}>
                            <NotificationsSidebarLink
                              href={feature.href}
                              icon={feature.icon}
                              name={feature.name}
                            />
                          </div>
                        )
                      }

                      return (
                        <div key={feature.name}>
                          {'links' in feature ? (
                            <AccordionItem key={feature.name}>
                              <AccordionButton icon={feature.icon}>
                                {feature.name}
                              </AccordionButton>
                              <AccordionPanel>
                                <div className="grid grid-cols-[auto,1fr] gap-4 pb-1 pt-2">
                                  <div
                                    className="ml-5 h-full w-px"
                                    css={css`
                                      background-color: var(--c-grey-400);
                                    `}
                                  />
                                  <div
                                    css={css`
                                      display: grid;
                                      gap: 4px;
                                      color: var(--color-blue-950-rgb);
                                      transition: var(--t-primary);
                                    `}
                                  >
                                    {feature.links.map((link) => {
                                      return (
                                        <SidebarLink
                                          href={link.href}
                                          key={link.name}
                                        >
                                          {link.name}
                                        </SidebarLink>
                                      )
                                    })}
                                  </div>
                                </div>
                              </AccordionPanel>
                            </AccordionItem>
                          ) : (
                            <SidebarLink
                              href={feature.href}
                              icon={feature.icon}
                              disabled={feature.disabled}
                            >
                              {feature.name}
                            </SidebarLink>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </Accordion>
              </div>
            )
          })}
        </div>
      </div>

      <div
        css={css`
          align-self: end;
        `}
      >
        <SidebarMenu />
      </div>
    </nav>
  )
}
