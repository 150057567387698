import { css } from '@emotion/react'
import Link from 'next/link'
import { FC } from 'react'

import { Button } from '@/design-system/Button'
import { Image } from '@/design-system/Image'
import { Subheading } from '@/design-system/Subheading'
import { Text } from '@/design-system/Text'

import { ErrorPageTemplate } from './ErrorPageTemplate'

const Unauthorized: FC = () => {
  return (
    <ErrorPageTemplate>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <div
          css={css`
            margin-bottom: 50px;
          `}
        >
          <Image
            src="DS-Squircle_Black_gzqsto"
            width={50}
            height={50}
            css={css`
              border-radius: 4px;
            `}
            alt="Unauthorized graphic"
          />
        </div>

        <Subheading
          fontSize={22}
          css={css`
            margin-bottom: 32px;
          `}
        >
          You don’t have access to this dealership.
        </Subheading>
        <Text
          fontSize={20}
          css={css`
            margin-bottom: 40px;
          `}
        >
          Please contact the dealership admin to gain access.
        </Text>

        <Link href="/">
          <Button variant="GHOST" colorScheme="grey">
            Back to home
          </Button>
        </Link>
      </div>
    </ErrorPageTemplate>
  )
}

export default Unauthorized
