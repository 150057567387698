import { MeQuery } from '../../generated/types-and-hooks'

export const groupBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K,
) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem)
    if (!previous[group]) previous[group] = []
    previous[group].push(currentItem)
    return previous
  }, {} as Record<K, T[]>)

export const getDomainByDealer = ({
  dealer,
  includePrefix = false,
}: {
  dealer: MeQuery['me']['dealers'][0]
  includePrefix?: boolean
}) => {
  const isLive = !!dealer.site.publishedAt
  const finalDomain = isLive
    ? dealer.site.domain
    : `${dealer.site.subdomain}.driverseat.io`

  return `${includePrefix ? 'https://' : ''}${finalDomain}`
}
