import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import slugify from 'slugify'

import { useMeQuery } from '../../../generated/types-and-hooks'
import { useSignOut } from '../apollo'

export const useUser = () => {
  const { isAuthenticated } = useAuth0()
  const router = useRouter()
  const signOut = useSignOut()
  const { data, error } = useMeQuery({
    skip: !isAuthenticated,
  })

  if (
    error?.message.includes('Unauthorized') ||
    error?.message.includes('No account')
  ) {
    signOut()
  }

  const dealer = router.route.includes('[dealerId]')
    ? data?.me.dealers.find(
        (dealership) =>
          slugify(dealership.name, { lower: true }) ===
          (router.query.dealerId as string),
      )
    : null

  return {
    user: data?.me,
    dealership: dealer,
  }
}
