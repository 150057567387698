import styled from '@emotion/styled'
import capsize from 'capsize'

const fontMetrics = {
  capHeight: 2048,
  ascent: 2728,
  descent: -680,
  lineGap: 0,
  unitsPerEm: 2816,
}

const lineGapMap = {
  14: 21,
  16: 24,
  18: 27,
  20: 30,
  22: 33,
  24: 36,
  26: 40,
}

type FontSizes = keyof typeof lineGapMap

const styles = (fontSize: FontSizes) =>
  capsize({
    fontMetrics,
    fontSize,
    leading: lineGapMap[fontSize],
  })

export const Heading = styled.p<{
  fontSize?: FontSizes
}>`
  letter-spacing: 0.02em;

  ${({ fontSize = 16 }) => ({ ...styles(fontSize) })}
  ${({ fontSize = 16 }) => ({
    fontWeight: fontSize !== 26 ? 600 : 700,
  })}
`
