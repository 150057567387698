import { Cloudinary, Transformation } from 'cloudinary-core'
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import { FC } from 'react'
import { thumbHashToDataURL } from 'thumbhash'

export const cld = new Cloudinary({ cloud_name: 'driverseat' })

const defaultTransformationOptions = {
  fetchFormat: 'auto',
  dpr: 'auto',
  crop: 'fit',
  quality: 'auto',
}

type ImageProps = NextImageProps & {
  imagePlaceholder?: string | null
  options?: Transformation | Transformation.Options
}

const base64ToBinary = (base64: string) =>
  new Uint8Array(
    atob(base64)
      .split('')
      .map((x) => x.charCodeAt(0)),
  )

const getBlurDataURL = (placeholder?: string | null) => {
  if (!placeholder) {
    return undefined
  }
  const thumbHashFromBase64 = base64ToBinary(placeholder)

  return thumbHashToDataURL(thumbHashFromBase64)
}

export const Image: FC<ImageProps> = ({
  src,
  options,
  imagePlaceholder,
  key,
  ...props
}) => {
  return (
    <NextImage
      loader={({ src, width, quality }) => {
        if (src.startsWith('https://')) {
          return src
        }

        return cld.url(src, {
          width,
          secure: true,
          ...defaultTransformationOptions,
          quality,
          ...options,
        })
      }}
      src={src}
      {...props}
      key={key}
      {...(imagePlaceholder
        ? {
            placeholder: 'blur',
            blurDataURL: getBlurDataURL(imagePlaceholder),
          }
        : {})}
    />
  )
}
