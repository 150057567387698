import { css } from '@emotion/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren } from 'react'

import { Text } from '@/design-system/Text'

type SidebarLinkProps = {
  icon?: IconProp
  href?: string
  disabled?: boolean
  onClick?: () => void
}

export const SidebarLink: FC<PropsWithChildren<SidebarLinkProps>> = ({
  children,
  icon,
  href,
  disabled = false,
  onClick,
}) => {
  const { asPath } = useRouter()

  const content = (
    <div
      css={css`
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        ${icon
          ? css`
              padding: 8px 12px;
            `
          : css`
              padding: 10px 12px;
            `}

        border-radius: 3px;
        transition: var(--t-primary);
        color: var(--color-blue-950-rgb);
        border: 1px solid transparent;

        ${disabled &&
        css`
          opacity: 50%;
          cursor: not-allowed;
        `}

        &:hover {
          background-color: var(--color-gray-100-rgb);
        }

        ${asPath === href &&
        css`
          background-color: var(--color-gray-100-rgb);
          font-weight: 500;
          color: var(--color-blue-700-rgb);
          border-color: var(--color-blue-100-rgb);
        `}
      `}
    >
      {icon ? (
        <div
          css={css`
            margin-right: 20px;
            width: 16px;
            display: flex;
            justify-content: center;
          `}
        >
          <FontAwesomeIcon
            css={css`
              font-size: 16px;
            `}
            icon={icon}
          />
        </div>
      ) : null}

      <Text
        css={css`
          user-select: none;
        `}
      >
        {children}
      </Text>
    </div>
  )

  if (disabled) {
    return content
  }

  if (href) {
    const isExternalLink = href.startsWith('https')

    if (isExternalLink) {
      return (
        <a
          href={href}
          {...(isExternalLink && {
            target: '_blank',
          })}
        >
          {content}
        </a>
      )
    }

    return <Link href={href}>{content}</Link>
  } else {
    return (
      <button className="w-full" onClick={onClick}>
        {content}
      </button>
    )
  }
}
