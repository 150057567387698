import { css } from '@emotion/react'
import { FC, PropsWithChildren } from 'react'

import { Heading } from '@/design-system/Heading'

const colorSchemeMap = {
  default: {
    color: `var(--color-blue-950-rgb)`,
    backgroundColor: `var(--c-grey-300)`,
  },
  positive: {
    color: `var(--color-green-700-rgb)`,
    backgroundColor: `var(--color-green-200-rgb)`,
  },
  negative: {
    color: `var(--color-orange-700-rgb)`,
    backgroundColor: `var(--color-orange-200-rgb)`,
  },
  primary: {
    color: `var(--color-blue-700-rgb)`,
    backgroundColor: `var(--color-blue-100-rgb)`,
  },
}

const smallTagStyles = css`
  padding: 4px 6px;
  border-radius: 4px;
`

const mediumTagStyles = css`
  padding: 0 12px;
  height: 24px;
  border-radius: 100px;
`

export type TagProps = {
  size?: 'SMALL' | 'MEDIUM'
  colorScheme?: 'default' | 'positive' | 'negative' | 'primary'
  backgroundColor?: string
}
export const Tag: FC<PropsWithChildren<TagProps>> = ({
  children,
  size = 'SMALL',
  colorScheme = 'default',
  backgroundColor,
}) => {
  const colors = colorSchemeMap[colorScheme]

  return (
    <div
      css={[
        size === 'SMALL' ? smallTagStyles : mediumTagStyles,
        css`
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: ${backgroundColor ? 'white' : colors.color};
          background-color: ${backgroundColor || colors.backgroundColor};
        `,
      ]}
    >
      {size === 'SMALL' ? (
        <p
          css={css`
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            letter-spacing: 0.02em;
          `}
        >
          {children}
        </p>
      ) : (
        <Heading fontSize={14}>{children}</Heading>
      )}
    </div>
  )
}
