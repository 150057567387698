import { createContext, useContext } from 'react'

import { MeQuery } from '../../generated/types-and-hooks'

type AuthContext = {
  user: MeQuery['me']
  dealer: MeQuery['me']['dealers'][0]
}

export const AuthContext = createContext<AuthContext | undefined>(undefined)

export const useAuthContext = (): AuthContext => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider')
  }
  return context
}
