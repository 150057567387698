import '@reach/combobox/styles.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import '@reach/dialog/styles.css'
import '@reach/menu-button/styles.css'
import '@reach/accordion/styles.css'
import '@reach/listbox/styles.css'
import '@reach/checkbox/styles.css'
import '@reach/tabs/styles.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-dates/lib/css/_datepicker.css'
import 'react-image-crop/dist/ReactCrop.css'
import 'react-dates/initialize'
import '@driverseat/tailwind-base/tailwind.css'
import '../styles/main.css'
import '../../client/styles/app.css'

import { Auth0Provider } from '@auth0/auth0-react'
import { config, library } from '@fortawesome/fontawesome-svg-core'
import {
  faBadgeDollar,
  faBadgePercent,
  faBell,
  faBolt,
  faCalendar,
  faCar,
  faCarBattery,
  faCarBuilding,
  faCarBurst,
  faCarGarage,
  faCars,
  faChargingStation,
  faCheckCircle,
  faClock,
  faComet,
  faComment,
  faCommentDollar,
  faCompass,
  faDiamondTurnRight,
  faFaceSmile,
  faFire,
  faGasPump,
  faGauge,
  faHeart,
  faRoad,
  faSparkles,
  faSprayCanSparkles,
  faStar,
  faSteeringWheel,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { AppProps } from 'next/app'
import { Inter } from 'next/font/google'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import { MobileNotSupportedModal } from '@/components/features/home/MobileNotSupportedModal'
import { CustomApolloProvider } from '@/components/global/apollo'
import { AdminLayout } from '@/components/global/layouts/AdminLayout'
import { AuthLayout } from '@/components/global/layouts/AuthLayout'
import { DefaultLayout } from '@/components/global/layouts/DefaultLayout'
import { useGoogleAnalytics } from '@/components/global/useGoogleAnalytics'

import { AUTH0_SCOPE, IS_SSR } from '../config/constants'

config.autoAddCss = false

library.add(
  ...[
    // Regular icons
    faSprayCanSparkles,
    faBadgeDollar,
    faBadgePercent,
    faBell,
    faBolt,
    faCalendar,
    faCar,
    faCarBattery,
    faCarBuilding,
    faCarBurst,
    faCarGarage,
    faCars,
    faChargingStation,
    faCheckCircle,
    faClock,
    faComet,
    faComment,
    faCommentDollar,
    faCompass,
    faDiamondTurnRight,
    faFire,
    faGasPump,
    faHeart,
    faRoad,
    faFaceSmile,
    faSparkles,
    faStar,
    faSteeringWheel,
    faGauge,
    faWrench,
  ],
)

const inter = Inter({ subsets: ['latin'] })
const App: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()
  const [isMobileNotSupportedModalOpen, setIsMobileNotSupportedModalOpen] =
    useState(false)

  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsMobileNotSupportedModalOpen(true)
    }
  }, [])

  useGoogleAnalytics()

  return (
    <div className={inter.className}>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string}
        authorizationParams={{
          redirect_uri: IS_SSR
            ? undefined
            : `${window.location.origin}/callback`,
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          scope: AUTH0_SCOPE,
        }}
        cacheLocation={IS_SSR ? undefined : 'localstorage'}
        useRefreshTokens
        sessionCheckExpiryDays={7}
        useCookiesForTransactions={!IS_SSR}
      >
        {isMobileNotSupportedModalOpen ? (
          <MobileNotSupportedModal
            isModalOpen={isMobileNotSupportedModalOpen}
            onClose={() => setIsMobileNotSupportedModalOpen(false)}
          />
        ) : (
          <CustomApolloProvider>
            {router.pathname === '/login' ||
            router.pathname === '/verify-request' ||
            router.pathname === '/invite' ? (
              <AuthLayout title="Login">
                <Component {...pageProps} />
              </AuthLayout>
            ) : router.pathname === '/' ||
              router.pathname === '/404' ||
              router.pathname === '/passes/[passId]' ||
              router.pathname === '/no-account' ||
              router.pathname === '/playground' ||
              router.pathname === '/new' ||
              router.pathname.startsWith('/trade-in') ? (
              <Component {...pageProps} />
            ) : router.pathname.startsWith('/admin') ? (
              <AdminLayout>
                <Component {...pageProps} />
              </AdminLayout>
            ) : (
              <DefaultLayout title="driverseat app">
                <Component {...pageProps} />
              </DefaultLayout>
            )}
          </CustomApolloProvider>
        )}
      </Auth0Provider>
    </div>
  )
}

export default App
