import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'

import { Sidebar } from '@/components/features/navigation/Sidebar'
import { useUser } from '@/components/global/hooks/useUser'
import Unauthorized from '@/components/global/Unauthorized'
import { Loading } from '@/components/Loading'

import { AuthContext } from '../AuthContext'

interface DefaultLayoutProps {
  title: string
}

export const DefaultLayout: FC<PropsWithChildren<DefaultLayoutProps>> = ({
  children,
}) => {
  const { user, dealership } = useUser()
  const { isAuthenticated, isLoading } = useAuth0()
  const [ready, setReady] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if (!ready) {
      setReady(true)
    }
  }, [])

  if (!ready) {
    return null
  }

  if (isLoading) {
    return <Loading fullScreen />
  }
  if (!isAuthenticated && !isLoading) {
    router.push('/login')
    return <Loading fullScreen />
  }

  if (user && !dealership) {
    return <Unauthorized />
  }

  if (!user || !dealership) {
    return <Loading fullScreen />
  }

  return (
    <AuthContext.Provider value={{ user, dealer: dealership }}>
      <Head>
        <title>Driverseat App</title>
      </Head>

      <main
        css={css`
          display: grid;
          grid-template-columns: 256px 1fr;
          min-height: 100vh;

          ${router.asPath.includes('/website/pages/') &&
          css`
            grid-template-columns: 1fr;
          `}
        `}
      >
        <Sidebar />

        <div
          css={css`
            display: flex;
            justify-content: center;
            width: 100%;
          `}
        >
          <div
            css={css`
              width: 100%;
            `}
          >
            {children}
          </div>
        </div>
        <Toaster />
      </main>
    </AuthContext.Provider>
  )
}
