import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  useApolloClient,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ApolloProvider } from '@apollo/client/react'
import { relayStylePagination } from '@apollo/client/utilities'
import { useAuth0 } from '@auth0/auth0-react'
import { FC, PropsWithChildren, useMemo } from 'react'

import { AUTH0_SCOPE, IS_SSR } from '../../config/constants'
import { StrictTypedTypePolicies } from '../../generated/apollo-helpers'

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_ENDPOINT,
})

export const CustomApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0()
  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        const getCtx = (accessToken?: string) => ({
          headers: {
            ...headers,
            ...(accessToken && { authorization: `Bearer ${accessToken}` }),
          },
        })

        if (isLoading) return getCtx()
        if (!isLoading && !isAuthenticated) return getCtx()

        if (IS_SSR) {
          return getCtx()
        }

        return new Promise((resolve) => {
          getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
              scope: AUTH0_SCOPE,
            },
          })
            .then((token) => resolve(getCtx(token)))
            .catch(async () => {
              await apolloClient.clearStore()
              logout({ logoutParams: { returnTo: window.location.origin } })
            })
        })
      }),
    [isLoading, isAuthenticated, getAccessTokenSilently],
  )

  const typePolicies: StrictTypedTypePolicies = {
    Query: {
      fields: {
        posts: relayStylePagination([
          'orderBy',
          'state',
          'createdBy',
          'query',
          'siteId',
        ]),
        vehicles: relayStylePagination([
          'orderBy',
          'sold',
          'condition',
          'query',
          'dealershipId',
        ]),
        consignments: relayStylePagination([
          'orderBy',
          'status',
          'end',
          'start',
          'query',
          'dealershipId',
        ]),
        myConsignments: relayStylePagination([
          'orderBy',
          'status',
          'end',
          'start',
          'after',
          'query',
          'customerId',
        ]),
        pages: relayStylePagination([
          'createdBy',
          'state',
          'orderBy',
          'query',
          'siteId',
        ]),
        customers: relayStylePagination(['query', 'dealershipId']),
        inquiries: relayStylePagination([
          'condition',
          'orderBy',
          'query',
          'dealershipId',
          'start',
          'end',
          'type',
          'service',
        ]),
        leads: relayStylePagination([
          'orderBy',
          'query',
          'dealershipId',
          'condition',
        ]),
        reports: relayStylePagination([
          'end',
          'start',
          'orderBy',
          'type',
          'query',
          'dealershipId',
          'createdBy',
          'reportCreationType',
        ]),
        members: relayStylePagination(['orderBy', 'dealershipId']),
        inventorySyncs: relayStylePagination(['orderBy', 'dealershipId']),
        notifications: relayStylePagination([
          'orderBy',
          'dealerId',
          'category',
          'state',
        ]),
        offers: relayStylePagination(['orderBy', 'dealershipId', 'query']),
        files: relayStylePagination(['dealershipId']),
        passes: relayStylePagination([
          'orderBy',
          'templateId',
          'query',
          'condition',
          'passProvider',
        ]),
        customOrderModels: relayStylePagination([
          'start',
          'end',
          'siteId',
          'orderBy',
        ]),
        userEvents: relayStylePagination(['dealerId', 'category', 'orderBy']),
        forms: relayStylePagination(['dealershipId', 'query']),
        formSubmissions: relayStylePagination(['formId']),
      },
    },
  }

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
          typePolicies,
        }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
          },
        },
      }),
    [authLink],
  )

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export const useSignOut = (): (() => void) => {
  const apolloClient = useApolloClient()
  const { logout } = useAuth0()

  return async (): Promise<void> => {
    await apolloClient.clearStore()
    await logout({ logoutParams: { returnTo: window.location.origin } })
  }
}
