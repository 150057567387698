import { css } from '@emotion/react'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from '@reach/combobox'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'

import { useAuthContext } from '@/components/global/AuthContext'
import { Tag } from '@/design-system/Tag'
import { Text } from '@/design-system/Text'

import { SearchQuery, useSearchQuery } from '../../../generated/types-and-hooks'

export type SearchProps = {
  open: boolean
  onDismiss: () => void
}

type SearchResult = SearchQuery['search'][0]

function groupBy(
  list: SearchQuery['search'],
  callback: (obj: SearchQuery['search'][0]) => string,
) {
  return list.reduce<Record<string, SearchResult[]>>((acc, x) => {
    const key = callback(x)
    if (!acc[key]) {
      return {
        ...acc,
        [key]: [x],
      }
    }
    return {
      ...acc,
      [key]: [...acc[key], x],
    }
  }, {})
}

const getText = (result: SearchResult): string => {
  if ('model' in result) {
    return `${result.year} ${result.make} ${result.model} ${result.trim}`
  }

  if ('name' in result && 'phoneNumber' in result) {
    return result.name
  }

  if ('userName' in result) {
    return result.userName as string
  }

  if ('comments' in result) {
    return result.lead.name
  }

  if ('slug' in result) {
    return result.title
  }

  return ''
}

export const Search: FC<SearchProps> = ({ open, onDismiss }) => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<[string, SearchResult[]][]>([])
  const { dealer } = useAuthContext()
  const router = useRouter()
  useSearchQuery({
    variables: {
      query,
      dealershipId: dealer.id,
    },
    skip: !query,
    onCompleted(data) {
      if (!data) return
      const groupedByResults = groupBy(
        data.search,
        (searchResult) => searchResult.__typename,
      )

      setResults(Object.entries(groupedByResults))
    },
  })

  const handleSearchElementClicked = async ({
    category,
    id,
  }: {
    category: string
    id: string
  }) => {
    onDismiss()
    setQuery('')
    switch (category) {
      case 'Page':
        router.push(`/${router.query.dealerId}/website/pages/${id}`)
        break
      case 'Post':
        router.push(`/${router.query.dealerId}/website/posts/${id}`)
        break
      case 'Vehicle':
        router.push(`/${router.query.dealerId}/inventory/vehicles/${id}`)
        break
      case 'Lead':
        router.push(`/${router.query.dealerId}/crm/leads/${id}`)
        break
      case 'User':
        router.push(`/${router.query.dealerId}/settings/members`)
        break
      case 'Consignment':
        router.push(`/${router.query.dealerId}/website/consignment/${id}`)
        break
    }
  }
  return (
    <div>
      <DialogOverlay
        isOpen={open}
        onDismiss={() => {
          onDismiss()
          setQuery('')
        }}
        css={css`
          background: transparent;
        `}
      >
        <DialogContent
          css={css`
            padding: 0;
            max-width: 480px;
          `}
        >
          <Combobox
            css={css`
              border-radius: 10px;
              box-shadow:
                0px -4px 391px rgba(0, 0, 0, 0.09),
                0px -1.6711px 163.35px rgba(0, 0, 0, 0.0559052),
                0px -0.893452px 87.335px rgba(0, 0, 0, 0.0396337),
                0px -0.500862px 48.9593px rgba(0, 0, 0, 0.0277915),
                0px -0.266004px 26.0019px rgba(0, 0, 0, 0.0178808);
            `}
          >
            <ComboboxInput
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              css={css`
                color: var(--color-blue-950-rgb);
                font-size: 18px;
                line-height: 27px;
                width: 100%;
                height: 70px;
                border: 0;
                outline: 0;
                padding-left: 25px;
                background: transparent;

                :focus {
                  outline: none;
                }
              `}
              placeholder="Search for anything ... "
            />
            <ComboboxPopover
              css={css`
                background: white;
                border-radius: 0 0 10px 10px;
                border: none;
              `}
              portal={false}
            >
              <div
                css={css`
                  width: 100%;
                  height: 1px;
                  background: var(--c-grey-300);
                `}
              />

              <div>
                {results.length > 0 ? (
                  <ComboboxList
                    css={css`
                      [data-reach-combobox-option][data-highlighted] {
                        background-color: var(--c-grey-50);

                        > span {
                          opacity: 1;
                        }
                      }

                      padding: 20px 25px;
                    `}
                  >
                    {results.map(([category, searchResults], index) => {
                      return (
                        <div key={category}>
                          <div
                            css={css`
                              margin-bottom: 10px;
                            `}
                          >
                            <Tag size="SMALL">{category}</Tag>
                          </div>
                          {searchResults.map((searchResult) => {
                            return (
                              <ComboboxOption
                                value={searchResult.id}
                                key={searchResult.id}
                                css={css`
                                  margin: 0 -5px;
                                  padding: 10px 10px;
                                  border-radius: 5px;
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  cursor: pointer;
                                  transition: var(--t-primary);

                                  &:hover {
                                    background-color: var(--c-grey-50);
                                  }

                                  &:hover > span {
                                    opacity: 1;
                                  }
                                `}
                                onClick={() => {
                                  handleSearchElementClicked({
                                    category,
                                    id: searchResult.id,
                                  })
                                }}
                              >
                                <Text
                                  css={css`
                                    color: var(--c-grey-700);
                                  `}
                                >
                                  {getText(searchResult)}
                                </Text>
                                <span
                                  css={css`
                                    opacity: 0;
                                    transition: var(--t-primary);
                                  `}
                                >
                                  <FontAwesomeIcon
                                    icon={faLongArrowRight}
                                    css={css`
                                      color: var(--color-blue-700-rgb);
                                    `}
                                  />
                                </span>
                              </ComboboxOption>
                            )
                          })}

                          {results.length - 1 !== index && (
                            <div
                              css={css`
                                padding-top: 10px;
                                padding-bottom: 20px;
                              `}
                            >
                              <div
                                css={css`
                                  height: 1px;
                                  width: 100%;
                                  background: var(--c-grey-300);
                                `}
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </ComboboxList>
                ) : (
                  <Text
                    css={css`
                      color: var(--c-grey-700);
                      padding: 25px;
                    `}
                    fontSize={16}
                  >
                    No Results...
                  </Text>
                )}
              </div>
            </ComboboxPopover>
          </Combobox>
        </DialogContent>
      </DialogOverlay>
    </div>
  )
}
