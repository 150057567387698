import { css } from '@emotion/react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useButton } from '@react-aria/button'
import { FC, PropsWithChildren, RefObject, useRef } from 'react'

import { Subheading } from '@/design-system/Subheading'

export type ButtonProps = {
  disabled?: boolean
  variant?: 'DEFAULT' | 'OUTLINE' | 'GHOST'
  size?: 'SMALL' | 'MEDIUM' | 'LARGE' | 'XL'
  type?: 'button' | 'submit' | 'reset'
  colorScheme?: 'primary' | 'black' | 'grey' | 'danger'
  leftIcon?: IconProp
  rightIcon?: IconProp
  href?: string
  onClick?(): void
  fullWidth?: boolean
  loading?: boolean
  buttonRef?: RefObject<HTMLButtonElement>
}

const baseButtonStyles = css`
  display: flex;
  align-items: center;
  transition: var(--t-primary);

  :disabled {
    cursor: not-allowed;
  }
`

const fullWidthButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const smallButtonStyles = css`
  padding: 0 16px;
  height: 28px;
  border-radius: 3px;
`

const mediumButtonStyles = css`
  padding: 0 14px;
  height: 32px;
  border-radius: 3px;
`

const largeButtonStyles = css`
  padding: 0 14px;
  height: 44px;
  border-radius: 4px;
`

const xlButtonStyles = css`
  padding: 0 20px;
  border-radius: 4px;
  height: 60px;
`

// TODO: Figure danger color-scheme button hover and outline + ghost styles
const colorSchemesMap = {
  DEFAULT: {
    primary: {
      color: 'white',
      borderColor: `var(--color-blue-700-rgb)`,
      backgroundColor: `var(--color-blue-700-rgb)`,
      hoverBackgroundColor: `var(--color-blue-800-rgb)`,
    },
    grey: {
      color: `var(--color-blue-950-rgb)`,
      borderColor: `var(--c-grey-100)`,
      backgroundColor: `var(--c-grey-100)`,
      hoverBackgroundColor: `var(--c-grey-400)`,
    },
    black: {
      color: 'white',
      borderColor: `rgba(0, 0, 0, 0.9)`,
      backgroundColor: `rgba(0, 0, 0, 0.9)`,
      hoverBackgroundColor: 'black',
    },
    danger: {
      color: 'white',
      borderColor: `var(--color-red-500-rgb)`,
      backgroundColor: `var(--color-red-500-rgb)`,
      hoverBackgroundColor: `var(--color-red-700-rgb)`,
    },
  },
  OUTLINE: {
    primary: {
      color: `var(--color-blue-700-rgb)`,
      borderColor: `var(--color-blue-700-rgb)`,
      backgroundColor: `var(--color-blue-700-rgb)`,
      hoverBackgroundColor: `hsla(var(--c-blue-500-hsl), 0.07)`,
    },
    black: {
      color: 'black',
      borderColor: 'black',
      backgroundColor: 'black',
      hoverBackgroundColor: `rgba(0, 0, 0, 0.07)`,
    },
    grey: {
      color: `var(--color-blue-950-rgb)`,
      borderColor: `var(--c-grey-300)`,
      backgroundColor: `var(--c-grey-500)`,
      hoverBackgroundColor: `hsla(var(--c-grey-500-hsl), 0.14)`,
    },
    danger: {
      color: `var(--color-red-500-rgb)`,
      borderColor: `var(--color-red-500-rgb)`,
      backgroundColor: `var(--color-red-500-rgb)`,
      hoverBackgroundColor: `hsla(var(--color-red-500-rgb-hsl), 0.07)`,
    },
  },
  GHOST: {
    primary: {
      color: `var(--color-blue-700-rgb)`,
      borderColor: '',
      backgroundColor: 'transparent',
      hoverBackgroundColor: `hsla(var(--c-blue-500-hsl), 0.07)`,
    },
    black: {
      color: 'black',
      borderColor: '',
      backgroundColor: 'transparent',
      hoverBackgroundColor: `rgba(0, 0, 0, 0.07)`,
    },
    grey: {
      color: `var(--color-blue-950-rgb)`,
      borderColor: '',
      backgroundColor: 'transparent',
      hoverBackgroundColor: `hsla(var(--c-grey-500-hsl), 0.07)`,
    },
    danger: {
      color: `var(--color-red-500-rgb)`,
      borderColor: '',
      backgroundColor: 'transparent',
      hoverBackgroundColor: `var(--color-red-200-rgb)`,
    },
  },
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  leftIcon,
  rightIcon,
  variant = 'DEFAULT',
  size = 'MEDIUM',
  type = 'button',
  colorScheme = 'primary',
  disabled = false,
  onClick,
  fullWidth = false,
  loading = false,
  buttonRef,
}) => {
  const colors = colorSchemesMap[variant][colorScheme]

  const defaultVariant = css`
    border: 1px solid ${colors.borderColor};
    background-color: ${colors.backgroundColor};
    color: ${colors.color};
    white-space: nowrap;

    :hover:enabled {
      cursor: pointer;
      background-color: ${colors.hoverBackgroundColor};
      border-color: ${colors.hoverBackgroundColor};
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    }

    :disabled {
      background: hsl(0, 0%, 95%);
      border: 1px solid hsl(0, 0%, 95%);
      color: var(--c-grey-700);
    }
  `

  const outlineVariant = css`
    border: 1px solid ${colors.borderColor};
    color: ${colors.color};
    background: white;

    :hover:enabled {
      cursor: pointer;
      background: ${colors.hoverBackgroundColor};
    }

    :active:enabled {
      opacity: 0.85;
    }

    :disabled {
      opacity: 0.4;
    }
  `

  const ghostVariant = css`
    border: 1px solid transparent;
    color: ${colors.color};
    background: transparent;

    :hover:enabled {
      cursor: pointer;
      background: ${colors.hoverBackgroundColor};
    }

    :active:enabled {
      opacity: 0.85;
    }

    :disabled {
      opacity: 0.4;
    }
  `

  const ref = useRef<HTMLButtonElement>(null)
  const { buttonProps } = useButton({}, ref)

  return (
    <button
      ref={buttonRef || ref}
      {...buttonProps}
      disabled={disabled}
      css={[
        baseButtonStyles,
        fullWidth && fullWidthButtonStyles,
        (size === 'SMALL' && smallButtonStyles) ||
          (size === 'MEDIUM' && mediumButtonStyles) ||
          (size === 'LARGE' && largeButtonStyles) ||
          (size === 'XL' && xlButtonStyles),
        (variant === 'DEFAULT' && defaultVariant) ||
          (variant === 'OUTLINE' && outlineVariant) ||
          (variant === 'GHOST' && ghostVariant),
      ]}
      onClick={onClick}
      type={type}
    >
      {leftIcon && (
        <FontAwesomeIcon
          css={css`
            ${size === 'SMALL' || size === 'MEDIUM'
              ? css`
                  margin-right: 8px;
                  font-size: 12px;
                `
              : css`
                  margin-right: 12px;
                  font-size: 14px;
                `};
          `}
          icon={leftIcon}
        />
      )}
      <Subheading as="span" fontSize={size === 'LARGE' ? 16 : 14}>
        {children}
      </Subheading>
      {loading && (
        <FontAwesomeIcon
          css={css`
            margin-left: 12px;
          `}
          icon={faSpinnerThird}
          spin
        />
      )}
      {rightIcon && (
        <FontAwesomeIcon
          css={css`
            margin-left: 12px;
          `}
          icon={rightIcon}
        />
      )}
    </button>
  )
}
