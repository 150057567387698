import { css } from '@emotion/react'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Dialog from '@radix-ui/react-dialog'
import { FC } from 'react'

import { Button } from '@/design-system/Button'
import { Heading } from '@/design-system/Heading'
import { Image } from '@/design-system/Image'
import { Text } from '@/design-system/Text'

type MobileNotSupportedModalProps = {
  isModalOpen: boolean
  onClose: () => void
}
export const MobileNotSupportedModal: FC<MobileNotSupportedModalProps> = ({
  isModalOpen,
  onClose,
}) => {
  return (
    <Dialog.Root open={isModalOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[1000000000] bg-black/50" />
        <Dialog.Content className="!pointer-events-none fixed left-0 top-0 z-[10000000000] flex size-full p-[15px] duration-1000 ease-in-out">
          <div
            style={{ pointerEvents: 'all' }}
            className="flex size-full min-h-fit flex-col justify-between rounded-[5px] bg-white/90 p-4 shadow-sm backdrop-blur-md"
          >
            <div className="flex size-full flex-col justify-between">
              <div className="flex w-full justify-between">
                <Image
                  src="black-logo"
                  width={30}
                  height={30}
                  alt="Dark DS logo"
                  css={css`
                    border-radius: 4px;
                  `}
                />
                <button
                  className="text-[22px] text-app-gray-700"
                  onClick={onClose}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>
              <div className="flex flex-col items-center">
                <div className="mb-5 text-2xl text-red-500">
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </div>
                <Heading fontSize={22} className="mb-5">
                  Mobile not yet supported
                </Heading>
                <Text className="mb-19 px-20 text-center text-app-gray-700">
                  Login from your desktop for the best experience
                </Text>
                <div>
                  <Image
                    src="undraw_engineering_team_u99p_1_ikj6mf"
                    width={232}
                    height={209}
                    alt="Graphic"
                  />
                </div>
              </div>
              <Button
                fullWidth
                variant="OUTLINE"
                colorScheme="black"
                size="LARGE"
                onClick={onClose}
              >
                Continue Anyway
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
