import { css } from '@emotion/react'
import {
  faArrowRightFromBracket,
  faCarBuilding,
  faCircleQuestion,
  faCog,
  faEllipsis,
  faRocket,
  faSparkles,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFuzzySearchList } from '@nozbe/microfuzz/react'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { keyframes, styled } from '@stitches/react'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import slugify from 'slugify'

import { SidebarLink } from '@/components/features/navigation/SidebarLink'
import { useSignOut } from '@/components/global/apollo'
import { useAuthContext } from '@/components/global/AuthContext'
import { groupBy } from '@/components/global/utils'
import { Loading } from '@/components/Loading'
import { cld } from '@/design-system/Image'
import { Subheading } from '@/design-system/Subheading'
import { Tag } from '@/design-system/Tag'
import { Text } from '@/design-system/Text'

import { isSuperAdmin } from '../../../config/constants'
import {
  UserRole,
  useUnreadNotificationsCountQuery,
} from '../../../generated/types-and-hooks'

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  width: 260,
  backgroundColor: 'white',
  borderRadius: 6,
  padding: 5,
  boxShadow:
    '0px -13px 80px rgba(0, 0, 0, 0.05), 0px -5.43109px 33.4221px rgba(0, 0, 0, 0.0359427), 0px -2.90372px 17.869px rgba(0, 0, 0, 0.0298054), 0px -1.6278px 10.0172px rgba(0, 0, 0, 0.025), 0px -0.864513px 5.32008px rgba(0, 0, 0, 0.0201946), 0px -0.359743px 2.21381px rgba(0, 0, 0, 0.0140573)',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
})

const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, {
  all: 'unset',
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  position: 'relative',
  userSelect: 'none',
  cursor: 'pointer',
  color: 'var(--color-blue-950-rgb)',

  '&:focus': {
    backgroundColor: 'var(--color-gray-100-rgb)',
  },
})

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: 'var(--c-grey-200)',
  margin: 5,
})

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: 'white',
  marginLeft: 5,
})

const StyledDropdownMenuRadioGroup = styled(
  DropdownMenuPrimitive.DropdownMenuRadioGroup,
  {
    maxHeight: '196px',
    overflowY: 'scroll',
  },
)

export const DropdownMenu = DropdownMenuPrimitive.Root
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger
export const DropdownMenuContent = StyledContent
export const DropdownMenuRadioGroup = StyledDropdownMenuRadioGroup
export const DropdownMenuRadioItem = StyledRadioItem
export const DropdownMenuItemIndicator = DropdownMenuPrimitive.ItemIndicator
export const DropdownMenuSeparator = StyledSeparator
export const DropdownMenuArrow = StyledArrow

const MenuButton = styled('button', {
  '&[data-state="open"]': {
    backgroundColor: 'var(--color-gray-100-rgb)',
  },
})

export const SidebarMenu = () => {
  const router = useRouter()
  const { user, dealer } = useAuthContext()
  const [dealership, setDealership] = useState<string>(
    slugify(dealer.name, {
      lower: true,
    }),
  )
  const { data: unreadNotificationData } = useUnreadNotificationsCountQuery({
    pollInterval: 60_000,
  })

  useEffect(() => {
    const slug = slugify(dealer.name, { lower: true })

    if (slug !== dealership) {
      setDealership(slug)
    }
  }, [dealer])

  const { query } = useRouter()
  const pathname = usePathname()
  const dealerId = query.dealerId
  const [displayOverlay, setDisplayOverlay] = useState(false)
  const signOut = useSignOut()

  const handleDealershipChange = (dealership: string) => {
    setDealership(dealership)
    setDisplayOverlay(true)
    const pathnameSplit = pathname.split('/')
    router
      .push(
        `/${dealership}/${pathnameSplit.slice(2, pathnameSplit[2] === 'offers' ? 3 : 4).join('/')}`,
      )
      .then(() => {
        setDisplayOverlay(false)
      })
  }

  const options = user.dealers.map((ds) => {
    const notificationCount =
      unreadNotificationData?.unreadNotificationsCount.filter(
        (e) => e.dealershipId === ds.id,
      )
    return {
      label: ds.nickname || ds.name,
      value: slugify(ds.name, { lower: true }),
      type: ds.__typename,
      id: ds.id,
      faviconImageId: ds.site.faviconPicture?.publicId,
      groupId: ds.parent ? ds.parent.id : ds.id,
      isGroup: ds.isDealershipGroup,
      unreadNotifications:
        !!notificationCount &&
        notificationCount.length > 0 &&
        notificationCount[0].count > 0
          ? notificationCount[0].count
          : 0,
    }
  })

  const [queryText, setQueryText] = useState('')

  const groupedOptions = groupBy(options, (e) => e.groupId)
  const flatOptions = Object.values(groupedOptions).flat()

  const filteredList = useFuzzySearchList({
    list: flatOptions,
    queryText,
    getText: (item) => [item.label],
    mapResultItem: ({ item }) => ({ item }),
  })

  const membership = user.dealershipMemberships.find((m) => {
    return m.dealership.id === dealer.id
  })

  if (!membership) {
    throw new Error('Membership not found')
  }

  return (
    <>
      <DialogOverlay
        css={css`
          background: rgba(255, 255, 255, 0.75);
        `}
        isOpen={displayOverlay}
      >
        <DialogContent
          css={css`
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            margin: 0;
          `}
        >
          <Loading />
        </DialogContent>
      </DialogOverlay>
      <DropdownMenu
        onOpenChange={(open) => {
          if (!open) {
            setQueryText('')
          }
        }}
      >
        <DropdownMenuTrigger asChild>
          <MenuButton className="flex w-full items-center justify-between rounded-[5px] bg-app-gray-100 px-3 py-2 hover:bg-app-gray-200">
            <div className="flex flex-1 items-center">
              <div className="flex items-center space-x-3">
                <Favicon
                  faviconImageId={dealer.site.faviconPicture?.publicId}
                  fallbackLetter={dealer.name.substring(0, 1)}
                />
                <Subheading>{dealer.nickname || dealer.name}</Subheading>
              </div>
            </div>
            <FontAwesomeIcon className="text-app-gray-700" icon={faEllipsis} />
          </MenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuContent sideOffset={8} align="start">
            {user.email.endsWith('@driverseat.io') && (
              <input
                autoFocus
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setQueryText(e.target.value)
                  e.stopPropagation()
                }}
                placeholder="Search..."
                value={queryText}
                type="search"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
                autoComplete="off"
                onBlur={() =>
                  document.getElementById('dealership-menu')?.focus()
                }
                className="mb-1 w-full rounded-md border border-gray-200 px-2 py-1 text-sm ring-blue-500 transition hover:border-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1"
              />
            )}
            <DropdownMenuRadioGroup
              value={dealership}
              onValueChange={handleDealershipChange}
              className="space-y-1"
            >
              {filteredList.map(({ item: option }) => {
                return (
                  <DropdownMenuRadioItem
                    key={option.value}
                    value={option.value}
                  >
                    <div className="flex w-full items-center justify-between space-x-4">
                      <div className="flex items-center space-x-4">
                        <Favicon
                          faviconImageId={option.faviconImageId}
                          fallbackLetter={option.label.substring(0, 1)}
                        />
                        <div className="flex items-center space-x-3">
                          <Text>{option.label}</Text>

                          {option.isGroup ? (
                            <FontAwesomeIcon
                              className="text-md leading-none text-app-gray-700"
                              icon={faCarBuilding}
                            />
                          ) : null}
                        </div>
                      </div>

                      {option.unreadNotifications > 0 &&
                        option.id !== dealer.id && (
                          <Tag>{option.unreadNotifications}</Tag>
                        )}
                      <DropdownMenuItemIndicator>
                        <FontAwesomeIcon
                          className="text-xl text-blue-500"
                          icon={faCheckCircle}
                        />
                      </DropdownMenuItemIndicator>
                    </div>
                  </DropdownMenuRadioItem>
                )
              })}
              {filteredList.length === 0 && <p>No results</p>}
            </DropdownMenuRadioGroup>
            <DropdownMenuSeparator />
            {membership.role === UserRole.Admin ? (
              <>
                <DropdownMenuPrimitive.DropdownMenuGroup className="space-y-1">
                  <DropdownMenuPrimitive.Item>
                    <SidebarLink
                      onClick={() =>
                        router.push(`/${dealerId}/settings/profile`)
                      }
                      icon={faUser}
                    >
                      Profile
                    </SidebarLink>
                  </DropdownMenuPrimitive.Item>
                  <DropdownMenuPrimitive.Item>
                    <SidebarLink
                      onClick={() =>
                        router.push(`/${dealerId}/settings/basics`)
                      }
                      icon={faCog}
                    >
                      Settings
                    </SidebarLink>
                  </DropdownMenuPrimitive.Item>
                  {isSuperAdmin(user.email) && (
                    <DropdownMenuPrimitive.Item>
                      <SidebarLink
                        onClick={() => router.push(`/${dealerId}/new`)}
                        icon={faRocket}
                      >
                        Add Dealership
                      </SidebarLink>
                    </DropdownMenuPrimitive.Item>
                  )}
                </DropdownMenuPrimitive.DropdownMenuGroup>

                <DropdownMenuSeparator />
              </>
            ) : null}

            <DropdownMenuPrimitive.DropdownMenuGroup className="space-y-1">
              <DropdownMenuPrimitive.Item>
                <SidebarLink
                  href="mailto:hello@driverseat.io"
                  icon={faCircleQuestion}
                >
                  Help
                </SidebarLink>
              </DropdownMenuPrimitive.Item>
              <DropdownMenuPrimitive.Item>
                <SidebarLink
                  href="https://driverseat.noticeable.news"
                  icon={faSparkles}
                >
                  What's New
                </SidebarLink>
              </DropdownMenuPrimitive.Item>
            </DropdownMenuPrimitive.DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuPrimitive.Item>
              <SidebarLink
                onClick={() => signOut()}
                icon={faArrowRightFromBracket}
              >
                Log out
              </SidebarLink>
            </DropdownMenuPrimitive.Item>

            <DropdownMenuArrow offset={12} />
          </DropdownMenuContent>
        </DropdownMenuPrimitive.Portal>
      </DropdownMenu>
    </>
  )
}

type FaviconProps = {
  faviconImageId?: string | null
  fallbackLetter: string
}
export const Favicon = ({ faviconImageId, fallbackLetter }: FaviconProps) => {
  if (faviconImageId) {
    return (
      <img
        alt="favicon"
        src={cld.url(faviconImageId)}
        className="size-5 rounded-sm object-contain"
      />
    )
  }
  return (
    <div className="flex size-5 items-center justify-center rounded-sm bg-black object-contain text-white">
      <Subheading fontSize={14}>{fallbackLetter}</Subheading>
    </div>
  )
}
