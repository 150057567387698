import { useAuth0 } from '@auth0/auth0-react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, PropsWithChildren, useEffect, useState } from 'react'

import { useUser } from '@/components/global/hooks/useUser'
import { Loading } from '@/components/Loading'

import { isSuperAdmin } from '../../../config/constants'

export const AdminLayout: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useUser()
  const { isAuthenticated, isLoading } = useAuth0()
  const [ready, setReady] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if (!ready) {
      setReady(true)
    }
  }, [])

  if (!ready) {
    return null
  }

  if (isLoading) {
    return <Loading />
  }
  if (!isAuthenticated && !isLoading) {
    router.push('/login')
    return <Loading />
  }

  if (!user) {
    return <Loading />
  }

  if (!isSuperAdmin(user.email)) {
    return null
  }

  return (
    <div>
      <Head>
        <title>Driverseat App</title>
      </Head>

      <main className="w-full">{children}</main>
    </div>
  )
}
