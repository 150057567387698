import { css } from '@emotion/react'
import Head from 'next/head'
import { FC, PropsWithChildren } from 'react'

import { Image } from '@/design-system/Image'
import { Text } from '@/design-system/Text'

type AuthLayoutProps = {
  title: string
  description?: string
}

const footerLinks = [
  {
    label: 'Terms',
    href: 'https://www.driverseat.io/terms-of-service',
  },
  {
    label: 'Privacy',
    href: 'https://www.driverseat.io/privacy-policy',
  },
  {
    label: 'Cookies',
    href: 'https://www.driverseat.io/cookies',
  },
]

export const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({
  children,
  title,
  description,
}) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Head>

      <div
        css={css`
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-rows: 100vh;
        `}
      >
        <div
          css={css`
            background: white;
            padding: 80px 80px 32px 80px;
            display: grid;
            grid-template-rows: auto 1fr auto;
          `}
        >
          <div
            css={css`
              display: inline-block;
              width: 35px;
            `}
          >
            <Image
              src="black-logo"
              width={35}
              height={35}
              css={css`
                border-radius: 5px;
              `}
              alt="Driverseat"
            />
          </div>

          <div
            css={css`
              height: 100%;
              display: flex;
              align-items: center;
            `}
          >
            {children}
          </div>

          <div>
            <div
              css={css`
                display: flex;
                > * ~ * {
                  margin-left: 20px;
                }
              `}
            >
              {footerLinks.map((link) => {
                return (
                  <a
                    key={link.label}
                    href={link.href}
                    target="_blank"
                    rel="noreferrer"
                    css={css`
                      color: var(--c-grey-700);
                      transition: var(--t-primary);

                      :hover {
                        cursor: pointer;
                        text-decoration: underline;
                      }
                    `}
                  >
                    <Text fontSize={14}>{link.label}</Text>
                  </a>
                )
              })}
            </div>
          </div>
        </div>
        <div
          css={css`
            position: relative;
            text-align: right;
          `}
        >
          <Image
            fill
            className="object-cover"
            src="https://source.unsplash.com/collection/613348/2000x1200"
            alt="Random image"
          />
          {/*<ReleaseCard />*/}
        </div>
      </div>
    </div>
  )
}
