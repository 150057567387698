import { css } from '@emotion/react'
import { FC, PropsWithChildren } from 'react'

export const ErrorPageTemplate: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      css={css`
        height: 100vh;
        padding: 40px;
      `}
    >
      <div
        css={css`
          background-color: var(--c-grey-50);
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
        `}
      >
        {children}
      </div>
    </div>
  )
}
